import { InitializeLazy } from 'pacto';


export class Action extends InitializeLazy {
  get settings() {
    return {
      selector: '.picture',
    };
  }

  get import() {
    return import(/* webpackChunkName: "common-picture" */ './Initialize');
  }
}
