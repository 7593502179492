import { InitializeLazy } from 'pacto';


export class Action extends InitializeLazy {
  get settings() {
    return {
      selector: '.article-placeholder-infobox',
    };
  }

  get import() {
    return import(/* webpackChunkName: "article-placeholder-infobox" */ './Initialize');
  }
}
