import { Initialize } from 'pacto';

import { TeaserXSArticleDefault as View } from '../views/TeaserXSArticleDefault';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.teaser-xs-article-default',
      namespace: 'teaser-xs-article-default:views',
      view: View,
    };
  }
}
