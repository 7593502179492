import { InitializeLazy } from 'pacto';


export class Action extends InitializeLazy {
  get settings() {
    return {
      selector: '.subnavigation',
    };
  }

  get import() {
    return import(/* webpackChunkName: "common-subnavigation" */ './Initialize');
  }
}
