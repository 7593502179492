import { Initialize } from 'pacto';

import { Contentblock as View } from '../views/Contentblock';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.contentblock-banner',
      namespace: 'contentblock-banner:views',
      view: View,
    };
  }
}
