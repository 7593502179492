import { Initialize } from 'pacto';

import { Calendar as View } from '../views/Calendar';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.common-calendar',
      namespace: 'calendar:views',
      view: View,
    };
  }
}
