import { Initialize } from 'pacto';

import { Carousel as View } from '../views/Carousel';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.carousel',
      namespace: 'carousel:views',
      view: View,
    };
  }
}
