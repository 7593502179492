import { ConsoleDebug } from '../../../_utils/ConsoleDebug';
import { Environment } from '../../../_utils/Environment';
import { Carousel as Caroucssel } from 'caroucssel';
import { Buttons, Pagination, Mask} from "caroucssel";
import { View } from 'pacto';
import { Picture } from "../../picture/views/Picture";


// @TODO: Use BEM helper here:
const CLASS_NAME = 'carousel';
const CLASS_BUTTON = `${CLASS_NAME}__button`;
const CLASS_BUTTON_NEXT = `${CLASS_BUTTON}--next`;
const CLASS_BUTTON_PREVIOUS = `${CLASS_BUTTON}--previous`;
const CLASS_PAGINATION_ENABLED = `${CLASS_NAME}--with-pagination`;
const CLASS_PAGINATION = `${CLASS_NAME}__pagination`;
const CLASS_MASK = `${CLASS_NAME}__mask`;
const SELECTOR_TARGET = `.${CLASS_NAME}__content`;


export class Carousel extends View {
  render() {
    this.instance = null;
    this.logInstance = new ConsoleDebug('debug-carousel', 'Carousel');

    this.customNewCaroucssel = this.customNewCaroucssel.bind(this);
    this.customNewCaroucssel();

    // EventListener setzen, diese kann von der BineosPlacement Klasse augerufen werden
    this.customRenewCarouselView = this.customRenewCarouselView.bind(this);
    this.el.querySelector('bineos-zone')?.addEventListener("customRenewCarouselView", this.customRenewCarouselView);
  }

  customNewCaroucssel() {
    this.logInstance.log('customNewCaroucssel() called');
    const target = this.el.querySelector(SELECTOR_TARGET);
    const hasPagination = this.el.classList.contains(CLASS_PAGINATION_ENABLED);

    this.instance = new Caroucssel(target, {
      features: [
        new Buttons({
          className: CLASS_BUTTON,
          nextClassName: CLASS_BUTTON_NEXT,
          nextLabel: 'Vor',
          nextTitle: 'Nach rechts scrollen',
          previousClassName: CLASS_BUTTON_PREVIOUS,
          previousLabel: 'Zurück',
          previousTitle: 'Nach links scrollen',
        }),
        new Pagination({
          className: CLASS_PAGINATION,
          title: ({ index }) => `Zur ${index + 1}. Seite scrollen`,

        }),
        new Mask({
          className: CLASS_MASK,
        })
      ],
    });
  }

  customDestroy() {
    this.logInstance.log('customDestroy() called');
    if (!this.instance) {
      return;
    }

    this.instance.destroy();
    this.instance = null;
  }

  // destroy and create new Caroucssel instance
  customRenewCarouselView() {
    this.logInstance.log('customRenewCarouselView() called');
    this.customDestroy();
    this.customNewCaroucssel();
  }
}
