import { Initialize } from 'pacto';

import { TeaserMArticleDefault as View } from '../views/TeaserMArticleDefault';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.teaser-m-article-default',
      namespace: 'teaser-m-article-default:views',
      view: View,
    };
  }
}
