import { View } from 'pacto';

export class ScrollToTop extends View {

  constructor(options) {
    super(options);
    this.onClick = this.onClick.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  render() {
    this.el.addEventListener('click', this.onClick);
    window.addEventListener('scroll', this.onScroll);
    this.updateVisibility();
    document.body.appendChild(this.el);
  }

  destroy() {
    this.el.removeEventListener('click', this.onClick);
    window.removeEventListener('scroll', this.onScroll);
    document.body.removeChild(this.el);
  }

  onClick(event) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  onScroll(event) {
    this.updateVisibility();
  }

  updateVisibility() {
    const windowHeight = window.innerHeight;
    if (window.pageYOffset > 1 * windowHeight) {
      this.el.classList.add('visible'); // Add a CSS class
    } else {
      this.el.classList.remove('visible'); // Remove the CSS class
    }
  }
}
