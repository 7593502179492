import { View } from 'pacto';


const CLASS_COLLAPSED = 'header--is-collapsed';
const SELECTOR_OPTION = '.teaser-l-article-default__link__element';


export class TeaserLArticleDefault extends View {
  constructor(options) {
    super(options);
    this.onIntersect = this.onIntersect.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  render() {
    const observer = new IntersectionObserver(
      this.onIntersect,
      { threshold: [0.9] },
    );
    observer.observe(this.el);
    const items = this.el.querySelectorAll(SELECTOR_OPTION);
    items.forEach((option) => option.addEventListener('click', this.onClick));
    this.items = items;
  }

  onIntersect(entries) {

    const isCollapsed = !entries.reduce(
      (acc, { isIntersecting }) => acc || isIntersecting,
      false,
    );

    this.el.classList[isCollapsed ? 'add' : 'remove'](CLASS_COLLAPSED);
  }

  onClick(event) {
    const { target } = event;
    const { href, title, dataset } = target;
    this.context.trigger('Teaser-l-article-default:click', { target: dataset.target });
    //event.preventDefault();
    //console.log(dataset.target)
  }
}
