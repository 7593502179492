import { Initialize } from 'pacto';

import { Sticky as View } from '../views/Sticky';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.sticky-banner',
      namespace: 'sticky-banner:views',
      view: View,
    };
  }
}
