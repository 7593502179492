import { Initialize } from 'pacto';
import { Externalcontent as View } from '../views/Externalcontent';

export class Action extends Initialize {
  get settings() {
    window.showEmbedContentCalled = false;
    return {
      selector: '.core-externalcontent, #dse',
      namespace: 'externalcontent:views',
      view: View,
    };
  }
}
