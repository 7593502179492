import { InitializeLazy } from 'pacto';


export class Action extends InitializeLazy {
  get settings() {
    return {
      selector: `
        .formfield input:not([type="radio"]),
        .formfield textarea,
        .formfield select
      `,
    };
  }

  get import() {
    return import(/* webpackChunkName: "common-formfield" */ './Initialize');
  }
}
