import { View } from 'pacto';


const CLASS_COLLAPSED = 'header--is-collapsed';
const SELECTOR_OPTION = '.wrapper';
const SELECTOR_OPTION_CONTAINER = '.sticky-banner';
const SELECTOR_OPTION_CLOSE = '.sticky-banner__content__close';


export class Sticky extends View {
  constructor(options) {
    super(options);
    this.onIntersect = this.onIntersect.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  render() {
    const observer = new IntersectionObserver(
      this.onIntersect,
      { threshold: [0.9] },
    );
    observer.observe(this.el);

    var close_banner = document.querySelector(SELECTOR_OPTION_CLOSE);
    var show_banner = document.querySelector(SELECTOR_OPTION_CONTAINER);

    close_banner.addEventListener('click', this.setStickyBannerCookie);
    window.onload = this.showStickyBanner;

    const items = this.el.querySelectorAll(SELECTOR_OPTION);
    items.forEach((option) => option.addEventListener('click', this.onClick));
    this.items = items;
  }

  onIntersect(entries) {

    const isCollapsed = !entries.reduce(
      (acc, { isIntersecting }) => acc || isIntersecting,
      false,
    );

    this.el.classList[isCollapsed ? 'add' : 'remove'](CLASS_COLLAPSED);
  }

  setStickyBannerCookie() {
    var cookieDomain = location.host.match("[^\.]*(\.[^\.]*\.[^\.]*)$")[1]; // mannheimer-morgen.de close soll auch fuer Subdomains gelten
    document.cookie = "stick_banner=close; expires=0; path=/; domain=" + cookieDomain;
    document.getElementById('sticky-banner-bottom').style.display = "none";
  }

    /*
     * Sticky-Banner anzeigen, wenn _kein_ "stick_banner=close"-Cookie vorhanden
     * und Cookie-Banner Button "displayCookieConsent=y" geklickt wurde
     */
  showStickyBanner() {
      if ( document.cookie.match("stick_banner=close") == null)
      {
          // Abonnenten kein Sticky-Banner ausspielen - $("#sticky-banner-bottom").data('skip-subscriber') == 1
          // if (( nfyCommunityUser.contentSubscription() == 1 ) )
          // {
          //     return;
          // }
          document.getElementById('sticky-banner-bottom').style.display = "block";
      }
  }

  onClick(event) {

    const { target } = event;
    const { href, title, dataset } = target;
    console.log(dataset.target);
    this.context.trigger('sticky-banner:click', { target: dataset.target });
  }
}
