import { View } from 'pacto';

const doc = document;
const CLASS_ALL_ACCEPT = '.checkbox__input--privacy-box';
const CLASS_SINGLE_ACCEPT = '.privacy-box-single';
const EMBED_COOKIE = 'mm_enable_embedd';
const EMBED_COOKIE_TWITTER = 'haas_enable_embed_twitter';
const EMBED_COOKIE_YT = 'haas_enable_embed_yt';
const EMBED_COOKIE_DPA = 'haas_enable_embed_dpa';
const EMBED_COOKIE_DW = 'haas_enable_embed_dw';
const EMBED_COOKIE_MISC = 'haas_enable_embed_misc';
const ID_DEAC_BUTTON = '#deactivateExternalContent';
const CLASS_MODULE_WRAPPER = '.moduleframe__content-wrapper';
const DSE_CONTAINER = '#dse';
const CLASS_MAIN_CONTAINER = '.core-externalcontent';

let contentLoaded = false;
let cookies = {};
export class Externalcontent extends View {

  constructor(options) {
    super(options);
    this.onClick = this.onClick.bind(this);
    this.showContent = this.showContent.bind(this);
    this.decodeAppend = this.decodeAppend.bind(this);
  }

  render() {
    let cookieCheck = false;

    cookies = {
      "old": EMBED_COOKIE,
      "youtube": EMBED_COOKIE_YT,
      "twitter": EMBED_COOKIE_TWITTER,
      "dpachart": EMBED_COOKIE_DPA,
      "datawrapper": EMBED_COOKIE_DW,
      "misc": EMBED_COOKIE_MISC
    };

    // Check if rendered js-json exists from redFACT
    if(typeof document.haas_embeddedcontent != 'undefined'){
      let service = this.el.querySelector(CLASS_MODULE_WRAPPER)?.getAttribute('data-type');
      if(this.checkEmbedCookie(cookies[service]) == 1) {
        this.showContent(service); // render json to "HTML-Inhalte" nodes
      } else {
        let singleAcceptEl = this.el.querySelector(CLASS_SINGLE_ACCEPT);
        singleAcceptEl?.addEventListener('click', this.onClick)
      }
    }

    let deactivateButton;
    let dseElement = doc.querySelector(DSE_CONTAINER);  // dsRequest is AJAX call object to load the datenschutzverordnung (dsgvo-tool)

    if(dseElement != null){
      if(window.dsRequest.readyState == 4) { // check if loaded. On first load its already "done"
        deactivateButton = doc.querySelector(ID_DEAC_BUTTON);
        if (deactivateButton != null) {
          deactivateButton.addEventListener('click', this.deactivateExternalContent);
        }
      }else{ // on reload the readyState increments until "done", watch tree mutation to check, if content was loaded
        let observer = new MutationObserver(mutationRecords => {
          deactivateButton = doc.querySelector(ID_DEAC_BUTTON);
          deactivateButton.addEventListener('click', this.deactivateExternalContent);
        });
        observer.observe(dseElement, {
          childList: true
        });
      }
    }
  }

  showContent(service, loadAll) {
    if(loadAll) {
      for (const [key, value] of Object.entries(doc.haas_embeddedcontent)) {
        this.decodeAppend(key, value, service)
      }
    } else {
      let id = this.el.querySelector(CLASS_MODULE_WRAPPER)?.id
      this.decodeAppend(id, doc.haas_embeddedcontent[id], service)
    }
  }

  decodeAppend(contentId, contentB64String, service) {
    let externalContentContainer = '';
    let documentFragment = '';
    externalContentContainer = doc.querySelector('#'+contentId)
    let type = externalContentContainer.getAttribute('data-type');
    let externalContentRange = doc.createRange();

    if(type == service) {
          externalContentRange.selectNode(doc.getElementsByTagName("BODY")[0]);
          documentFragment = externalContentRange.createContextualFragment(atob(contentB64String.text));
          externalContentContainer.innerHTML = '';
          externalContentContainer.appendChild(documentFragment);
          let mainContainer = externalContentContainer.closest(CLASS_MAIN_CONTAINER);
          mainContainer.style.backgroundColor = '#fff';
          mainContainer.setAttribute('data-loaded', true);
      }
  }

  deactivateExternalContent() {
    this.setEmbedCookie(EMBED_COOKIE, 0);
    doc.querySelector(ID_DEAC_BUTTON).innerHTML = 'Laden wurde deaktiviert';
  }

  onClick(event) {
    let externalContentContainer = this.el.querySelector(CLASS_MODULE_WRAPPER);
    let clickedService = externalContentContainer.getAttribute('data-type');
    let clickedServiceTypeCookie = cookies[clickedService];

    let allAcceptEl = this.el.querySelector(CLASS_ALL_ACCEPT);

    if(allAcceptEl.checked) {
      this.setEmbedCookie(clickedServiceTypeCookie, 1);
      this.showContent(clickedService, true);
    } else {
      this.showContent(clickedService, false);
    }
  }

  checkEmbedCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  }

  setEmbedCookie(cname, cvalue){
    const d = new Date();
    d.setTime(d.getTime() + (365*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    return true;
  }

}
