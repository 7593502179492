import { InitializeLazy } from 'pacto';


export class Action extends InitializeLazy {
  get settings() {
    return {
      selector: '.switch-button',
    };
  }

  get import() {
    return import(/* webpackChunkName: "common-accordion" */ './Initialize');
  }
}
