import { Initialize } from 'pacto';

import { TeaserXLArticleDefault as View } from '../views/TeaserXLArticleDefault';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.teaser-xl-article-default',
      namespace: 'teaser-xl-article-default:views',
      view: View,
    };
  }
}
