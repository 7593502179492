import { Initialize } from 'pacto';
import { ScrollToTop } from '../views/ScrollToTop';

export class Action extends Initialize {
  get settings() {
    return {
      selector: '.common-scrolltotop',
      namespace: 'scrollToTop',
      view: ScrollToTop,
    };
  }
}
