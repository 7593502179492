import 'what-input';
import 'wicg-inert';
import { Context } from 'pacto';

// Common components:
import { Action as Background } from './components/common/background/actions/InitializeLazy';

import { Action as Formfield } from './components/common/forms/formfield/_base/actions/InitializeLazy';
import { Action as List } from './components/common/list/actions/InitializeLazy';
import { Action as Loadmore } from './components/common/loadmore/actions/InitializeLazy';
import { Action as Locationselect } from './components/common/locationselect/actions/InitializeLazy';
import { Action as Password } from './components/common/forms/formfield/password/actions/InitializeLazy';
import { Action as Picture } from './components/common/picture/actions/InitializeLazy';
import { Action as Sharing } from './components/common/sharing/actions/InitializeLazy';
import { Action as Subnavigation } from './components/common/subnavigation/actions/InitializeLazy';
import { Action as Tabnavigation } from './components/common/tabnavigation/actions/InitializeLazy';
import { Action as Accordion } from './components/common/accordion/actions/InitializeLazy';
import { Action as Switch } from './components/common/forms/formfield/switch/actions/InitializeLazy';
import { Action as Externalcontent } from './components/core/externalcontent/actions/Initialize';
import { Action as Calendar } from './components/common/calendar/actions/Initialize';
import { Action as Carousel } from './components/common/carousel/actions/Initialize';
import { Action as ScrollToTop } from './components/common/scrolltotop/actions/Initialize';

// Core components:

// Article components:
import { Action as PlaceholderInfobox } from './components/article/placeholder/infobox/actions/InitializeLazy';

// List components:
import { Action as LocationList } from './components/list/location/actions/InitializeLazy';

// Commcerical components:

// Teaser components:
import { Action as TeaserXLArticleDefault } from './components/teaser/xl/article/default/actions/Initialize';
import { Action as TeaserLArticleDefault } from './components/teaser/l/article/default/actions/Initialize';
import { Action as TeaserMArticleDefault } from './components/teaser/m/article/default/actions/Initialize';
import { Action as TeaserSArticleDefault } from './components/teaser/s/article/default/actions/Initialize';
import { Action as TeaserXSArticleDefault } from './components/teaser/xs/article/default/actions/Initialize';

// Banner components:
import { Action as StickyBanner } from './components/banner/sticky/actions/Initialize';
import { Action as ContentBanner } from './components/banner/contentblock/actions/Initialize';

// Search components:
import { Action as SearchResultForm } from './components/search/result/form/actions/InitializeLazy';

// Newsletter components:
import { Action as NewsletterForm } from './components/newsletter/formular/actions/InitializeLazy';
import { Action as NewsletterItem } from './components/newsletter/item/actions/InitializeLazy';

// This is required to setup webpack to find all dynamic imports correctly.
// This publicUrl is setup and passed by the critical js include.
// eslint-disable-next-line
__webpack_public_path__ = window.app.publicUrl;


const context = new Context({
  // Enable the event history (log over all triggered events and data). This
  // might be required by components that rely on events that where triggered
  // before they are initialized to catchup the current application state.
  // For more see: https://github.com/schorfES/pacto#context
  history: true,
});

// Attach all initialize actions of components to the "start"-event:
context.actions.add('app:run', [

  // Common components:
  Background,
  Formfield,
  List,
  Loadmore,
  Locationselect,
  Calendar,
  Password,
  Picture,
  Sharing,
  Subnavigation,
  Tabnavigation,
  Accordion,
  Switch,
  Externalcontent,
  Carousel,
  ScrollToTop,

  // Article components:
  PlaceholderInfobox,

  // List components:
  LocationList,

  // Teaser components:
  TeaserXLArticleDefault,
  TeaserLArticleDefault,
  TeaserMArticleDefault,
  TeaserSArticleDefault,
  TeaserXSArticleDefault,

  // Banner components:
  StickyBanner,
  ContentBanner,

  // Search components
  SearchResultForm,

  // Newsletter components
  NewsletterForm,
  NewsletterItem
]);

// do not work context.actions.add('modal-init', [Modal]);
context.trigger('app:run');
// Execute the application initialization by triggering the "start"-event:

