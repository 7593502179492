import { Initialize } from 'pacto';

import { TeaserLArticleDefault as View } from '../views/TeaserLArticleDefault';


export class Action extends Initialize {
  get settings() {
    return {
      selector: '.teaser-l-article-default',
      namespace: 'teaser-l-article-default:views',
      view: View,
    };
  }
}
