import { View } from 'pacto';

const SELECTOR_LIST_YEARS = '.common-calendar__list--years';
const SELECTOR_TABLE_DAYS = '.common-calendar__table--days';
const SELECTOR_ACTIVE = '.common-calendar__list--active';
const SELECTOR_SELECTED_MONTH = '.selected-month';
const SELECTOR_PREV_MONTH = '.common-calendar__month-nav--prev-month';
const SELECTOR_NEXT_MONTH = '.common-calendar__month-nav--next-month';
const TOGGLE_ACTIVE = 'common-calendar__list--active';
const START_DATE = new Date(2009, 3, 5);
const FAKE_DATE = '/archiv_jahr,2021_monat,1_tag,6.html';

let pathname = '';
let now = new Date();
let nowYear = now.getFullYear();
let nowMonth = now.getMonth();
let nowDay = now.getDate();
let nowWeekDay = now.getDay();
let initalSelectedDate;
let initalSelectedYear;
let initalSelectedMonth;
let initalSelectedDay;
let selectedDate;
let selectedYear;
let selectedMonth;
let selectedDay;

const germanMonths = ['Januar', 'Februar', 'März',
                    'April', 'Mai', 'Juni',
                    'Juli', 'August', 'September',
                    'Oktober', 'November', 'Dezember']

export class Calendar extends View {

  constructor(options) {
    super(options);

    // Initialisierung aller vorhandenen Funktion, welche im this nutzbar sein sollen.
    this.calcMonth = this.calcMonth.bind(this);
    this.setMonth = this.setMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.prevMonth = this.prevMonth.bind(this);

    this.calcYears = this.calcYears.bind(this);
    this.createYearEls = this.createYearEls.bind(this);
    this.setYear = this.setYear.bind(this);

    this.createDayEls = this.createDayEls.bind(this);
    this.setDay = this.setDay.bind(this);
  }

  render() {

    // Wenn Fractal Web UI Server, dann via GET
    if (window.location.host.match(/^localhost/) === null) {
      pathname = window.location.pathname;
    }else{
      pathname = FAKE_DATE;
    }
    pathname = pathname.split('_');
    var tempYear = this.splitNfyUrl(pathname[1], 'jahr');
    var tempMonth = this.splitNfyUrl(pathname[2], 'monat');
    var tempDay = this.splitNfyUrl(pathname[3], 'tag');

    // Setzen der Steuervariablen für Jahr, Monat und Tag
    initalSelectedYear  = ( tempYear ? tempYear : nowYear);
    initalSelectedMonth = ( tempMonth ? (Number(tempMonth) - Number(1)) : nowMonth);
    initalSelectedDay   = ( tempDay ? tempDay : nowDay);
    selectedYear        = initalSelectedYear
    selectedMonth       = initalSelectedMonth
    selectedDay         = initalSelectedDay

    // Basiert auf der alten URL Struktur (/archiv/2022/3/8)
    // initalSelectedYear  = (typeof pathname[2] != 'undefined' ? pathname[2] : nowYear);
    // initalSelectedMonth = (typeof pathname[3] != 'undefined' ? (Number(pathname[3]) - Number(1)) : nowMonth);
    // initalSelectedDay   = (typeof pathname[4] != 'undefined' ? pathname[4] : nowDay);
    // selectedYear        = (typeof pathname[2] != 'undefined' ? pathname[2] : nowYear);
    // selectedMonth       = (typeof pathname[3] != 'undefined' ? (Number(pathname[3]) - Number(1)) : nowMonth);
    // selectedDay         = (typeof pathname[4] != 'undefined' ? pathname[4] : nowDay);

    initalSelectedDate = new Date(initalSelectedYear, initalSelectedMonth, initalSelectedDay);
    selectedDate = new Date(selectedYear, selectedMonth, selectedDay);

    let leftYears = (nowYear - START_DATE.getFullYear());
    if(this.calcYears(leftYears)) {
      this.setMonth(selectedMonth);
    }

    let activeEls = document.querySelectorAll(SELECTOR_ACTIVE);

    document.querySelector(SELECTOR_NEXT_MONTH).addEventListener('click', this.nextMonth);
    document.querySelector(SELECTOR_PREV_MONTH).addEventListener('click', this.prevMonth);
  }

  splitNfyUrl(urlSegment, type) {
     if(typeof urlSegment != 'undefined'){
      var temp = urlSegment.split(',');
      if(temp[0] == type){
        if(temp[1].match(/\.html/)){
          return temp[1].replace(/\.html/, '');
        } else {
          return temp[1];
        }
      }
    }
     return false;
  }

  // Funktion zum Errechnen der Jahre bis 2009
  calcYears(leftYears) {
    let yearListEl = document.querySelector(SELECTOR_LIST_YEARS);
    let i = 0;

    while (i <= leftYears) {
      yearListEl.append(this.createYearEls(nowYear - i));
      i++;
    }
    return true;
  }

  // Funktion zum Erstellen der Jahres-HTML-Steuerelemente
  createYearEls(year){
    let liEl = document.createElement('LI');
    let aEl = document.createElement('A');

    aEl.setAttribute('href', '#');
    aEl.setAttribute('data-year', year);
    aEl.innerText = year;


    if(year == selectedYear) {
      aEl.classList.add(TOGGLE_ACTIVE);
      selectedYear = year;
    }

    aEl.addEventListener('click', this.setYear)
    liEl.append(aEl);

    return liEl;
  }

  // Funktion zum Setzen des geklickten Jahres
  setYear(aEl) {
    let clickedYearEl = aEl.target;

    Array.from(document.querySelectorAll(SELECTOR_LIST_YEARS + ' ' + SELECTOR_ACTIVE)).forEach( (el) => {
      el.classList.remove(TOGGLE_ACTIVE);
    });

    clickedYearEl.classList.add(TOGGLE_ACTIVE);

    selectedYear = clickedYearEl.getAttribute('data-year');
    document.querySelector(SELECTOR_TABLE_DAYS).innerHTML = '';
    this.setMonth(selectedMonth);
  }

  // Funktion zum Setzen des geklickten Monats
  // Außerdem Steuerung der Monatsnavigationspfeile
  setMonth(newMonth = false) {

    if(newMonth || newMonth == 0) {
      selectedMonth = newMonth
    }else {
      selectedMonth = nowMonth;
    }

    document.querySelector(SELECTOR_SELECTED_MONTH).innerText = germanMonths[selectedMonth] + ' ' + selectedYear;

    let nextMonth = (Number(selectedMonth) + Number(1));
    let prevMonth = (Number(selectedMonth) - Number(1));

    if((prevMonth > -1 && nextMonth < 12) ) {
      document.querySelector(SELECTOR_PREV_MONTH).style.visibility = 'visible';
      document.querySelector(SELECTOR_NEXT_MONTH).style.visibility = 'visible';
      document.querySelector(SELECTOR_NEXT_MONTH).setAttribute('data-next-month', nextMonth);
      document.querySelector(SELECTOR_PREV_MONTH).setAttribute('data-prev-month', prevMonth);
    }else{
      if(prevMonth == -1) {
        document.querySelector(SELECTOR_PREV_MONTH).style.visibility = 'hidden';
        document.querySelector(SELECTOR_NEXT_MONTH).setAttribute('data-next-month', nextMonth);
      }
      if(nextMonth == 12) {
        document.querySelector(SELECTOR_NEXT_MONTH).style.visibility = 'hidden';
        document.querySelector(SELECTOR_PREV_MONTH).setAttribute('data-prev-month', prevMonth);
      }
    }

    this.calcMonth()
  }

  // Funktion zum Errechnen der Tage im Kalender
  calcMonth() {
    let daysInSelectedMonth = new Date(selectedYear, (Number(selectedMonth)+Number(1)), 0).getDate();
    let lastDayOfSelectedMonth = new Date(selectedYear, (Number(selectedMonth)+Number(1)), 0).getDay();
    let firstDayInSelectedMonth = new Date(selectedYear, selectedMonth, 1).getDay();

    let prevMonthDate = new Date(selectedYear, selectedMonth, 0);
    let daysInSelectedPrevMonth = prevMonthDate.getDate();

    let nextMonthDate;

    if(selectedMonth < 11){
      nextMonthDate = new Date(selectedYear, (Number(selectedMonth)+Number(1)), 1);
    }else{
      nextMonthDate = new Date((Number(selectedYear)+1), 0, 1);
    }

    let leftDaysTillMonday;
    if(firstDayInSelectedMonth  < 1) {
        leftDaysTillMonday = 6;
    }else{
      leftDaysTillMonday = -(Number(1) - Number(firstDayInSelectedMonth));
    }

    let lastMonthStart = (Number(daysInSelectedPrevMonth) - (Number(leftDaysTillMonday)-1));

    // Schleife welche den Kalender mit Tagen aus dem vorherigem Monat auffüllt
    if(leftDaysTillMonday > 0) {
      let iLm = 0;
      let lastMonthFillerDays;
      while (iLm < leftDaysTillMonday){
        lastMonthFillerDays = (Number((lastMonthStart) + Number(iLm)));
        this.createDayEls(prevMonthDate, lastMonthFillerDays, 'inactive');
        iLm++;
      }
    }

    // Schleife welche den Kalender mit den Tagen des ausgewählten Monats füllt
    let i = 1;
    while(i <= daysInSelectedMonth) {
      this.createDayEls(false, i);
      i++;
    }

    // Schleife welche den Kalender mit den Tagen aus dem nächsten Monat auffüllt
    i = 1;
    if(lastDayOfSelectedMonth > 0) {
      var dayFiller = lastDayOfSelectedMonth;
      while(dayFiller < 7) {
        this.createDayEls(nextMonthDate, i, 'inactive');
        i++;
        dayFiller++;
      }
    }
  }

  // Funktion zum Setzen des geklickten Tages
  setDay(aEl) {
    let clickedDayEl = aEl.target;

    Array.from(document.querySelectorAll(SELECTOR_TABLE_DAYS+' '+SELECTOR_ACTIVE)).forEach( (el) => {
      el.classList.remove(TOGGLE_ACTIVE);
    });

    selectedDay = clickedDayEl.innerText;
    selectedDate =  new Date(selectedYear, selectedMonth, selectedDay);

    clickedDayEl.classList.add(TOGGLE_ACTIVE);
  }

  // Funktion zum Erstellen der Tages-HTML-Elemente (Anchor und label)
  createDayEls(customDate = false, dayIterator, customClass = false) {
      var month;
      var year;
      var date;
      if(customDate) {
         month = customDate.getMonth();
         year = customDate.getFullYear();
         date = customDate;
      }else {
        month = selectedMonth;
        year = selectedYear
        date = selectedDate;
      }

      var labelDayEl = document.createElement('LABEL');
      labelDayEl.setAttribute('data-date', year+'-'+(Number(month) +1)+'-'+dayIterator);
      labelDayEl.innerText = dayIterator;
      labelDayEl.addEventListener('click', this.setDay);

      if(labelDayEl.getAttribute('data-date') == initalSelectedYear + '-'  + (Number(initalSelectedMonth) +1) + '-' + initalSelectedDay){
        labelDayEl.classList.add(TOGGLE_ACTIVE);
      }

      var ankerDayEl = document.createElement('A');
      // Alte URL Struktur … (/archiv/2022/3/8)
      // ankerDayEl.setAttribute('href', '/archiv/'+year+'/'+(Number(month) +1)+'/'+dayIterator);

      // Neue URL Struktur … (/archiv_jahr,2022_monat,3_tag,8)
      ankerDayEl.setAttribute('href', '/archiv_jahr,'+year+'_monat,'+(Number(month) +1)+'_tag,'+dayIterator);

      if(customClass){
        labelDayEl.classList.add(customClass);
      }

      ankerDayEl.append(labelDayEl);
      document.querySelector(SELECTOR_TABLE_DAYS).append(ankerDayEl);
  }

  nextMonth(el) {
      let newMonth = el.target.getAttribute('data-next-month');
      document.querySelector(SELECTOR_TABLE_DAYS).innerHTML = '';
      if(newMonth < 12) {
        this.setMonth(newMonth);
      }
  }

  prevMonth(el) {
     let newMonth = el.target.getAttribute('data-prev-month');
     document.querySelector(SELECTOR_TABLE_DAYS).innerHTML = '';
      if(newMonth > -1) {
        this.setMonth(newMonth);
      }
  }
}
